import './style.css'
import * as THREE from 'three'
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader'
import { FlyControls } from 'three/examples/jsm/controls/FlyControls'
import { gsap } from 'gsap'
import * as lil from 'lil-gui'




/**
 * 
 * Start values for page content
 * 
 */
gsap.set('.homeContent header',{yPercent:-150})
gsap.set('.homeContent section',{xPercent:-150})
gsap.set('.homeImages .polaroid',{xPercent:1000})
gsap.set('.aboutContent header',{yPercent:-150})
gsap.set('.aboutContent section',{xPercent:-150})
gsap.set('.aboutContent aside',{xPercent:150})
gsap.set('.eventContent header',{yPercent:-150})
gsap.set('.eventControls .lftArr',{xPercent:-1000})
gsap.set('.eventControls .rgtArr',{xPercent:1000})
gsap.set('.ticketButton',{yPercent:1000})
gsap.set('.mediaContent header',{yPercent:-150})
gsap.set('.mediaControls .lftArr',{xPercent:-1000})
gsap.set('.mediaControls .rgtArr',{xPercent:1000})
gsap.set('.contactContent header',{yPercent:-150})

/**
 * Loading manager
 */
const loadManager = new THREE.LoadingManager()

const progress = document.getElementById('progress')

loadManager.onProgress = function(url, loaded, total){
    progress.style.height = (((loaded / total) * 100)+'%')
}


/**
 * Base
 */
// Debug
const gui = new lil.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')
// Events Carousel

// Scene
const scene = new THREE.Scene(loadManager)
//AxesHelper
const axisHelper = new THREE.AxesHelper(100)
scene.add(axisHelper)
//GLTFLoader
const gltfloader = new GLTFLoader(loadManager);
//Carousel Content Loader
const contentLoader = new THREE.TextureLoader(loadManager)
//load content for events carousel
const slide1 = contentLoader.load('./images/img1.jpg')
const slide2 = contentLoader.load('./images/img2.jpg')
const slide3 = contentLoader.load('./images/img3.jpg')
const slide4 = contentLoader.load('./images/img4.jpg')
const slide5 = contentLoader.load('./images/img5.jpg')


/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xffffff, 0.5)
scene.add(ambientLight)
/*Point Light*/
const ptLt = new THREE.PointLight(0xffffff, 0.9, 500, 2)
ptLt.position.set(1, -0.5, 1)
scene.add(ptLt)
/*Directional Light*/
const drctLgt = new THREE.DirectionalLight(0x00fffc, 0.3)
drctLgt.position.set(1, 0.25, 0)
scene.add(drctLgt)
/*HemisphereLight*/
const hemiLgt = new THREE.HemisphereLight(0xff0000, 0x0000ff, 0.3)
scene.add(hemiLgt)
/*RectAreaLight*/
const rectLt = new THREE.RectAreaLight(0x4e00ff, 2, 1, 1)
rectLt.position.set(-1.5, 0, 1.5)
rectLt.lookAt(new THREE.Vector3())
scene.add(rectLt)
/*SpotLight*/
const spotLt = new THREE.SpotLight(0x78ff00, 0.5, 10, Math.PI*0.1, 0.25, 1)
spotLt.position.set(0, 2, 3)

/**
 * Light Helpers
 */
//HemisphereLightHelper
const hemiLtHp = new THREE.HemisphereLightHelper(hemiLgt, 0.2)
scene.add(hemiLtHp)
//DirectionalLightHelper
const drctLtHp = new THREE.DirectionalLightHelper(drctLgt, 0.2)
scene.add(drctLtHp)
//PointLightHelper
const ptLtHp = new THREE.PointLightHelper(ptLt, 0.2)
scene.add(ptLtHp)
//SpotLightHelper
const sptLtHp = new THREE.SpotLightHelper(spotLt)
scene.add(sptLtHp)

/******************************
 *  3D Models
 ******************************/
/**
 * Night Club Model
 */
let loadedModel;
let corkBoard;
let iphone;
gltfloader.load('./assets/3Dmodels/nightclub/scene.gltf',(glftScene)=>{
    loadedModel = glftScene
    //adjust rotation and position of gltfScene
    glftScene.scene.rotation.y = Math.PI
    scene.add(glftScene.scene)
})
/**
 * Media iPhone
 */
gltfloader.load('./assets/3Dmodels/iphone/ix.glb',(glftScene)=>{
    iphone = glftScene
    //adjust rotation and position of gltfScene
    glftScene.scene.rotation.y = (Math.PI + 0.75)
    //glftScene.scene.quaternion.set(0,0.36945016406152975,0,0.9292505454800168)
    glftScene.scene.scale.set(0.025,0.025,0.025)
    glftScene.scene.position.set(8.31527080469144, 2.7, -0.29559561856200317)
    // glftScene.scene.rotateX(-(Math.PI*2.5))
    // glftScene.scene.rotateZ(-(Math.PI*2.75))
    scene.add(glftScene.scene)
})
/**
 * Contact bulletinboard/corkboard
 */
gltfloader.load('./assets/3Dmodels/cork_board/scene.gltf',(glftScene)=>{
    corkBoard = glftScene
    //adjust rotation and position of gltfScene
    glftScene.scene.rotation.y = Math.PI
    glftScene.scene.scale.set(0.5,0.5,0.5)
    glftScene.scene.position.set(0, 4.3, 19.8)
    scene.add(glftScene.scene)
})


/**
 * SpotLight/DirectLight Targets
 */
// spotLt.target = cube
// drctLgt.target = sphere
// scene.add(spotLt, spotLt.target, drctLgt.target)
// scene.add(sphere, cube, torus, plane)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

//adjust sizes on window size change
window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    //update content div
    //webContainer.setAttribute('width', window.innerWidth)

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 0
camera.position.y = 4.25
camera.position.z = 23
scene.add(camera)

/**
 * Controls
 */
//const controls = new OrbitControls(camera, canvas)
const controls = new FlyControls(camera, canvas)
controls.movementSpeed = 0.005
controls.rollSpeed = 0.00025
controls.dragToLook = true
//controls.activeLook = false
//controls.enableDamping = true
//Debug Controls
gui.add(camera.position, 'x').min(-20).max(20).step(0.1).name('direct x')
gui.add(camera.position, 'y').min(-20).max(20).step(0.1).name('direct y')
gui.add(camera.position, 'z').min(-20).max(20).step(0.1).name('direct z')
gui.add(camera.rotation, 'x').min(-20).max(20).step(0.1).name('rotate x')
gui.add(camera.rotation, 'y').min(-20).max(20).step(0.1).name('rotate y')
gui.add(camera.rotation, 'z').min(-20).max(20).step(0.1).name('rotate z')
/*
gui.add(ambientLight, 'intensity').min(0).max(1).step(0.0001).name('amb intensity')
gui.add(hemiLgt, 'intensity', 0, 1, 0.0001).name('hemi intensity')
gui.add(drctLgt.position, 'x').min(-20).max(20).step(0.1).name('direct x')
gui.add(drctLgt.position, 'y').min(-20).max(20).step(0.1).name('direct y')
gui.add(drctLgt.position, 'z').min(-20).max(20).step(0.1).name('direct z')
gui.add(ptLt.position, 'x').min(-20).max(20).step(0.1).name('pt x')
gui.add(ptLt.position, 'y').min(-20).max(20).step(0.1).name('pt y')
gui.add(ptLt.position, 'z').min(-20).max(20).step(0.1).name('pt z')
gui.add(spotLt.position, 'x').min(-20).max(20).step(0.1).name('sl x')
gui.add(spotLt.position, 'y').min(-20).max(20).step(0.1).name('sl y')
gui.add(spotLt.position, 'z').min(-20).max(20).step(0.1).name('sl z')
*/
/**
 * Renderer(s)
 */
//3d Renderer
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))


/**
 * Animate
 */
const clock = new THREE.Clock()

/*******************
*
*Animate the camera
*
********************/
//set view target positions
//View1 (Home)
//0 4.3 40
const testViewGeo = new THREE.SphereGeometry(0.05, 2, 2)
const testViewMat = new THREE.MeshBasicMaterial({color:'white', wireframe: true})
const testView1 = new THREE.Mesh(testViewGeo, testViewMat)
testView1.position.x = 0
testView1.position.y = 4.3
testView1.position.z = 15
//View2 (About)x0.9 y0.8 z-5.5 //cam: x:-3.5, y:4.3, z:-10.5
const testView2 = new THREE.Mesh(testViewGeo, testViewMat)
testView2.position.x = 0.9
testView2.position.y = 0.8
testView2.position.z = -5.5
/******************************************
 * 
 * View3 (Events)
 * 
*******************************************/
const testView3 = new THREE.Mesh(testViewGeo, testViewMat)
testView3.position.x = 0
testView3.position.y = 2.7
testView3.position.z = -12
//create the material to add the content textures
const screen1 = new THREE.MeshBasicMaterial({map: slide1})
const screen2 = new THREE.MeshBasicMaterial({map: slide2})
const screen3 = new THREE.MeshBasicMaterial({map: slide3})
const screen4 = new THREE.MeshBasicMaterial({map: slide4})
const screen5 = new THREE.MeshBasicMaterial({map: slide5})
//create the carousel and add it to the scene
const eventsGeo = new THREE.PlaneGeometry(2,2,1,1)
const eventsMat = testViewMat
const eventsScreen1 = new THREE.Mesh(eventsGeo,screen1)
eventsScreen1.position.y = 2.8
const eventsScreen2 = new THREE.Mesh(eventsGeo,screen2)
eventsScreen2.position.y = 2.8
const eventsScreen3 = new THREE.Mesh(eventsGeo,screen3)
eventsScreen3.position.y = 2.8
const eventsScreen4 = new THREE.Mesh(eventsGeo,screen4)
eventsScreen4.position.y = 2.8
const eventsScreen5 = new THREE.Mesh(eventsGeo,screen5)
eventsScreen5.position.y = 2.8
//eventsScreen.position.set(0,2.8,-12)
scene.add(eventsScreen1, eventsScreen2, eventsScreen3, eventsScreen4, eventsScreen5)
//View4 (Contact)
const testView4 = new THREE.Mesh(testViewGeo, testViewMat)
testView4.position.x = 0
testView4.position.y = 4.3
testView4.position.z = 19.8
//View5 (Media)x5.1 y2.7 z-3.7 //cam x8.5 y2.7 z-0.1
const testView5 = new THREE.Mesh(testViewGeo, testViewMat)
testView5.position.x = 5.1
testView5.position.y = 2.7
testView5.position.z = -3.7
//add the test views
scene.add(testView1, testView2, testView3, testView4, testView5)
//set init camera view direction
camera.lookAt(0, 0, 0)
//set target view directions
testView1.lookAt(0,4.3,40)
testView2.lookAt(-3.5,4.3,-10.5)
testView3.lookAt(0,2.7,-9)
testView4.lookAt(0,4.3,15)
testView5.lookAt(8.5,2.7,-0.1)
//gather nuttons to add event listners
const homeButton = document.getElementById('home')
const aboutButton = document.getElementById('about')
const eventsButton = document.getElementById('events')
const mediaButton = document.getElementById('media')
const contactButton = document.getElementById('contact')
//initialize quaternions for view rotation animation
var startOrientation = camera.quaternion.clone();
var targetOrientation = testView1.quaternion.clone().normalize();



/**
 * 
 * Page Content Animation Timelines
 * 
 */
function homeTween(){
    var homeTween = gsap.timeline()
    homeTween.fromTo('.homeContent header', {yPercent:-150},{yPercent:0, duration:1.5})
        .fromTo('.homeContent section',{xPercent:-150} ,{xPercent:0, duration:1.5}, '<')
        .fromTo('#p1',{xPercent:1000, rotate:-360} ,{xPercent:0, rotate:20,duration:1.5}, '<')
        .fromTo('#p2',{xPercent:1000, rotate:360} ,{xPercent:0, rotate:-12,duration:1.5}, '<')
        .fromTo('#p3',{xPercent:1000, rotate:-360} ,{xPercent:0, rotate:-30,duration:1.5}, '<')
    return homeTween
}
//Initial Animation
loadManager.onLoad = ()=>{
    gsap.to('.loadingScreen', {height: 0, duration:1.5})
    gsap.to('#loadingTxt', {fontSize: 0, duration:1.5})
    gsap.to( camera.position, {
        duration: 2, x:0, y:4.25, z:19,
        onUpdate: function() {
            camera.quaternion.copy(startOrientation).slerp(targetOrientation, this.progress());
        }
    } )
    homeTween()
}

var prevAnim = homeTween()

function aboutTween(){
    var aboutTween = gsap.timeline()
    aboutTween.fromTo('.aboutContent header', {yPercent:-150},{yPercent:0, duration:1.5})
        .fromTo('.aboutContent section',{xPercent:-150} ,{xPercent:0, duration:1.5}, '<')
        .fromTo('.aboutContent aside',{xPercent:150} ,{xPercent:0, duration:1.5}, '<')
    return aboutTween
}

function eventTween(){
    var eventTween = gsap.timeline()
    eventTween.fromTo('.eventContent header', {yPercent:-150},{yPercent:0, duration:1.5})
        .fromTo('.eventContent .lftArr',{xPercent:-1000} ,{xPercent:0, duration:1.5}, '<')
        .fromTo('.eventContent .rgtArr',{xPercent:1000} ,{xPercent:0, duration:1.5}, '<')
        .fromTo('.ticketButton',{yPercent:1000},{yPercent:0, duration:1.5}, '<')
    return eventTween
}

function mediaTween(){
    var mediaTween = gsap.timeline()
    mediaTween.fromTo('.mediaContent header', {yPercent:-150},{yPercent:0, duration:1.5})
        .fromTo('.mediaContent .lftArr',{xPercent:-1000} ,{xPercent:0, duration:1.5}, '<')
        .fromTo('.mediaContent .rgtArr',{xPercent:1000} ,{xPercent:0, duration:1.5}, '<')
        return mediaTween
}

function contactTween(){
    var contactTween = gsap.timeline()
    contactTween.fromTo('.contactContent header', {yPercent:-150},{yPercent:0, duration:1.5})
        return contactTween
}
    

//add click action to menu buttons
homeButton.onclick = ()=>{
    //gsap.to(camera.position,{z:20, duration:2})
    prevAnim.reverse();
    homeTween();
    startOrientation = camera.quaternion.clone();
    targetOrientation = testView1.quaternion.clone().normalize();
    gsap.to( camera.position, {
    duration: 2, x:0, y:4.25, z:19,
    onUpdate: function() {
        camera.quaternion.copy(startOrientation).slerp(targetOrientation, this.progress());
    }
} )
    prevAnim = homeTween();
}
eventsButton.onclick = ()=>{
    //gsap.to(camera.position,{z:20, duration:2})
    prevAnim.reverse()
    eventTween()
    startOrientation = camera.quaternion.clone();
    targetOrientation = testView3.quaternion.clone().normalize();
    gsap.to( camera.position, {
    duration: 2, z:-9, y:2.7, x:0,
    onUpdate: function() {
        camera.quaternion.copy(startOrientation).slerp(targetOrientation, this.progress());
    }
} )
    prevAnim = eventTween();
}
aboutButton.onclick = ()=>{
    //console.log(testView5.quaternion.clone().normalize())
    //gsap.to(camera.position,{z:20, duration:2})
    prevAnim.reverse()
    aboutTween()
    startOrientation = camera.quaternion.clone();
    targetOrientation = testView2.quaternion.clone().normalize();
    gsap.to( camera.position, {
        duration: 2, x:-3.5, y:4.3, z:-10.5,
        onUpdate: function() {
            camera.quaternion.copy(startOrientation).slerp(targetOrientation, this.progress());
        }
    } )
    prevAnim = aboutTween()
}
mediaButton.onclick = ()=>{
    //gsap.to(camera.position,{z:20, duration:2})
    prevAnim.reverse()
    mediaTween()
    startOrientation = camera.quaternion.clone();
    targetOrientation = testView5.quaternion.clone().normalize();
    gsap.to( camera.position, {
    duration: 2, x:8.5, y:2.7, z:-0.1,
    onUpdate: function() {
        camera.quaternion.copy(startOrientation).slerp(targetOrientation, this.progress());
    }
} )
prevAnim = mediaTween()
}
contactButton.onclick = ()=>{
    prevAnim.reverse()
    contactTween()
    startOrientation = camera.quaternion.clone();
    targetOrientation = testView4.quaternion.clone().normalize();
    gsap.to( camera.position, {
    duration: 2, x:0, y:4.25, z:18,
    onUpdate: function() {
        camera.quaternion.copy(startOrientation).slerp(targetOrientation, this.progress());
    }
} )
prevAnim = contactTween()
}

//log camera position
// const camPos = document.getElementById('logo')
// camPos.onclick = ()=>{console.log(camera.position)}




const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // Update camera
    //camera.position.z = Math.sin(elapsedTime)*5
    // Update controls
    controls.update(elapsedTime)
    const screenAngle1 = elapsedTime*0.25
    // const screenAngle2 = elapsedTime * 0.2
    // const screenAngle3 = elapsedTime * 0.4
    // const screenAngle4 = elapsedTime * 0.6
    // const screenAngle5 = elapsedTime * 0.8
    eventsScreen1.position.x = Math.cos(screenAngle1)*2.45
    eventsScreen1.position.z = (Math.sin(screenAngle1)*2.45)-14
    eventsScreen2.position.x = Math.cos(screenAngle1+1.257)*2.45
    eventsScreen2.position.z = (Math.sin(screenAngle1+1.257)*2.45)-14
    eventsScreen3.position.x = Math.cos(screenAngle1+2.514)*2.45
    eventsScreen3.position.z = (Math.sin(screenAngle1+2.514)*2.45)-14
    eventsScreen4.position.x = Math.cos(screenAngle1+3.771)*2.45
    eventsScreen4.position.z = (Math.sin(screenAngle1+3.771)*2.45)-14
    eventsScreen5.position.x = Math.cos(screenAngle1+5.028)*2.45
    eventsScreen5.position.z = (Math.sin(screenAngle1+5.028)*2.45)-14


    //update the spotlight add directlight helpers
    sptLtHp.update()
    drctLtHp.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()